<!-- 营销管理 实体充电卡 离线卡管理 绑定离线卡 -->
<template>
  <ykc-drawer :show.sync="showDrawer" destroy-on-close :title="drawerTitle">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="drawer-main-body">
      <ykc-form>
        <div style="padding-top: 20px">
          <ykc-form ref="YkcForm" :model="model" :rules="rules">
            <ykc-form-item label="电站名称" prop="stationName">
              <ykc-dropdown
                placeholder="请选择电站名称"
                :data="stationList"
                :configSet="{
                  label: 'stationName',
                  value: 'stationName',
                }"
                @change="changeType"
                v-model="model.stationName"></ykc-dropdown>
            </ykc-form-item>
            <ykc-form-item label="选择电桩" prop="pileList">
              <ykc-tree
                :data="treeData"
                :props="treeProps"
                :nodeKey="nodeKey"
                ref="YkcTree"
                @check-change="handleTreeCheckChange"></ykc-tree>
            </ykc-form-item>
            <ykc-form-item label="绑定卡号" required>
              <ChargeCard
                ref="ChargeCard"
                v-model="model.cardList"
                :cardIdValidator="cardIdValidator" />
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { barrierPage, chargingCardManage } from '@/service/apis';
  import ChargeCard from '@/components/dynamic-compose/charge-card.vue';

  const initModel = () => ({
    stationName: '',
    operatorId: '',
    pileList: [],
    cardList: [],
  });

  export default {
    props: ['showDrawer'],
    components: {
      ChargeCard,
    },
    data() {
      return {
        model: initModel(),
        nodeKey: 'pileId',
        drawerTitle: '绑定离线卡',
        treeData: [],
        stationList: [],
        selectRoleListId: [], // 第一步 回显
        basicData: [
          {
            comName: 'YkcInput',
            value: '',
            key: 'cardId',
            maxlength: 30,
            prepend: '卡号',
            placeholder: '请输入充电卡号',
            rules: [
              { required: true, message: '请输入充电卡号', trigger: 'blur' },
              {
                pattern: regexpObj.regexp.input.accountType.regexp,
                message: '请输入正确的充电卡号',
                trigger: 'blur',
              },
              { validator: this.checkCardNumber, trigger: 'blur' },
            ],
          },
        ],
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.model = initModel();
                  this.$emit('closeDrawer');
                },
              });
            },
          },

          {
            label: '确认',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.beforeEnsure();
            },
          },
        ],
        rules: {
          stationName: [
            { required: true, message: '请输入电站名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.nameType.errorTips.error('电站名称'),
            },
          ],
          pileList: [{ required: true, type: 'array', message: '请选择关联电桩', trigger: 'blur' }],
        },
        treeProps: {
          label: 'pileName',
          children: 'subNodes',
          disabled: 'disabled',
        },
      };
    },
    watch: {
      showDrawer(val) {
        if (val) {
          this.handleOpen();
        }
      },
    },
    methods: {
      cardIdValidator() {
        return new Promise(resolve => {
          resolve({ success: true });
        });
      },
      // 角色类型切换
      changeType(val) {
        this.getMenuTree(val);
      },
      getDetail() {
        barrierPage.findStationList({}).then(res => {
          if (res?.length > 0) {
            this.stationList = res;
          }
        });
      },
      // 抽屉展示
      async handleOpen() {
        this.model = initModel();
        this.treeData = [];
        this.model.cardList = [
          {
            cardId: '',
            success: false,
          },
        ];
        this.getDetail();
      },
      // 菜单树变化
      handleTreeCheckChange() {
        const keys = this.$refs.YkcTree.getCheckedKeys(true);
        console.log('keys', keys);
        this.model.pileList = keys;
      },
      // 菜单树初始化
      async getMenuTree(val) {
        this.model.pileList = [];
        if (val) {
          chargingCardManage.offQueryPileInfo({ stationName: val }).then(res => {
            this.model.stationId = res.stationId;
            this.treeData = res.pileList || [];
          });
        }
      },
      // 保存
      async beforeEnsure() {
        const promisify = item =>
          new Promise(resolve => {
            item.validate(valid => {
              resolve(valid);
            });
          });
        Promise.all([promisify(this.$refs.YkcForm), this.$refs.ChargeCard.validate()]).then(res => {
          if (res.every(valid => !!valid)) {
            const pileList = [];
            for (let i = 0; i < this.treeData.length; i++) {
              for (let j = 0; j < this.model.pileList.length; j++) {
                if (this.treeData[i].pileId === this.model.pileList[j]) {
                  pileList.push({
                    pileId: this.treeData[i].pileId,
                    pileCode: this.treeData[i].pileCode,
                  });
                }
              }
            }
            const cardNoList = [];
            this.model.cardList.forEach(item => {
              cardNoList.push(item.cardId);
            });
            const reqParams = {
              stationId: this.model.stationId,
              pileList,
              cardNoList,
            };
            chargingCardManage.offBind(reqParams).then(() => {
              this.model = initModel();
              this.$emit('closeDrawer');
              this.$emit('finish');
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
